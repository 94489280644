import moment from 'moment'
import gql from 'graphql-tag'

import { QueryName } from '../queryNames'
import { organization_events_insert_input } from '../../../types/globalTypes'

export const updateOrganizationQuery = (
  id: number,
  display_pricing: boolean,
  display_name: string,
  pims_email?: string,
  primary_phone_number?: string,
  number_of_vets?: string,
  default_weight_unit?: string
) => ({
  query: gql`
    mutation UpdateOrganization(
      $id: Int!
      $display_pricing: Boolean!
      $display_name: String!
      $pims_email: String
      $number_of_vets: String
      $primary_phone_number: String
      $default_weight_unit: String
    ) {
      update_organizations_by_pk(
        pk_columns: { id: $id }
        _set: {
          display_pricing: $display_pricing
          display_name: $display_name
          pims_email: $pims_email
          number_of_vets: $number_of_vets
          primary_phone_number: $primary_phone_number
          default_weight_unit: $default_weight_unit
        }
      ) {
        id
      }
    }
  `,
  route: 'update_organizations_by_pk',
  variables: { id, display_pricing, display_name, number_of_vets, pims_email, primary_phone_number, default_weight_unit },
})

export const fetchOrganizationsQuery = (enterprise_id?: number) => ({
  query: gql`
    query Organizations($enterpriseIdWhere: organizations_bool_exp!) {
      organizations(where: $enterpriseIdWhere, order_by: [{ display_name: asc }, { primary_email: asc }]) {
        id
        created_at
        display_name
        price_per_consultation_usd
        primary_email
        price_group_id
        dicom_servers {
          aet
          id
          ip_address
        }
      }
    }
  `,
  variables: { enterpriseIdWhere: enterprise_id ? { enterprise_id: { _eq: enterprise_id } } : {} },
  route: 'organizations',
})

export const stripeIdForOrganizationQuery = (id: number) => ({
  query: gql`
    query StripeIdForOrganization($id: Int!) {
      users(where: { organization_id: { _eq: $id } }) {
        related_accounts {
          stripe_profile {
            id
          }
        }
      }
    }
  `,
  route: 'users',
  variables: { id },
})

export const salesDataQuery = () => ({
  query: gql`
    query SalesData($thirty_days_ago: timestamptz!, $one_year_ago: timestamptz!) {
      organizations(where: { display_name: { _niregex: "(reader|test)" } }, order_by: { display_name: asc }) {
        display_name
        created_at
        primary_email

        price_group(order_by: { stat_type: { hours: desc } }) {
          amount
          addon_type
          addon_id
          is_exotic
          consultation_type_id
          stat_type {
            hours
            display_name
          }
        }

        distribution_emails(order_by: { id: desc }) {
          display_name
          email
        }

        dicom_servers {
          cases(where: { created_at: { _gt: $one_year_ago } }) {
            created_at
            consultations {
              id
            }
          }
        }

        ai_views_one_month: organization_events_aggregate(
          where: { organization_event_type_id: { _eq: 1 }, created_at: { _gt: $thirty_days_ago } }
        ) {
          aggregate {
            count
          }
        }

        ai_interactions_one_month: organization_events_aggregate(
          where: { organization_event_type_id: { _in: [35, 36] }, created_at: { _gt: $thirty_days_ago } }
        ) {
          aggregate {
            count
          }
        }

        viewer_views_one_month: organization_events_aggregate(
          where: { organization_event_type_id: { _eq: 34 }, created_at: { _gt: $thirty_days_ago } }
        ) {
          aggregate {
            count
          }
        }

        ai_views_three_months: organization_events_aggregate(
          where: { organization_event_type_id: { _eq: 1 }, created_at: { _gt: $thirty_days_ago } }
        ) {
          aggregate {
            count
          }
        }

        ai_interactions_three_months: organization_events_aggregate(
          where: { organization_event_type_id: { _in: [35, 36] }, created_at: { _gt: $thirty_days_ago } }
        ) {
          aggregate {
            count
          }
        }

        viewer_views_three_months: organization_events_aggregate(
          where: { organization_event_type_id: { _eq: 34 }, created_at: { _gt: $thirty_days_ago } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  route: 'organizations',
  variables: { thirty_days_ago: moment().subtract(30, 'days'), one_year_ago: moment().subtract(365, 'days') },
})

export const insertOrganizationEventQuery = (object: organization_events_insert_input) => ({
  query: gql`
    mutation InsertOrganizationEvent($object: organization_events_insert_input!) {
      insert_organization_events_one(object: $object) {
        id
      }
    }
  `,
  route: 'insert_organization_events_one',
  variables: { object },
})

export const insertOrganizationEventsQuery = (objects: organization_events_insert_input[]) => ({
  query: gql`
    mutation InsertOrganizationEvents($objects: [organization_events_insert_input!]!) {
      insert_organization_events(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  route: 'insert_organization_events',
  variables: { objects },
})

export const fetchOrganizationEventsQuery = (name = QueryName.FetchOrganizationEvents) => ({
  query: gql`
    query OrganizationEvents {
      organization_events(where: { organization_id: { _nin: [45, 78] } }, order_by: { id: desc }, limit: 1000) {
        created_at
        additional_data
        organization {
          display_name
        }
        organization_event_type {
          id
          description
        }
      }
    }
  `,
  route: 'organization_events',
  name,
})

export const fetchPricesGroupsQuery = (enterprise_id: number) => ({
  query: gql`
    query PriceGroups($enterprise_id: Int!) {
      price_groups(order_by: { id: desc }, where: { enterprise_id: { _eq: $enterprise_id } }) {
        id
        display_name
        organizations_aggregate {
          aggregate {
            count
          }
        }
        prices {
          id
          addon_type
          amount
          consultation_type_id
          currency_type_id
          is_exotic
          price_group_id
          software_charge_type_id
          addon {
            id
            display_name
            additional_data
          }
          stat_type {
            id
            display_name
            hours
          }
        }
      }
    }
  `,
  route: 'price_groups',
  variables: { enterprise_id },
})

export const updateOrganizationEnterpriseQuery = (id: number, enterprise_id: number) => ({
  query: gql`
    mutation UpdateOrganizationEnterprise($id: Int!, $enterprise_id: Int!) {
      update_organizations_by_pk(pk_columns: { id: $id }, _set: { enterprise_id: $enterprise_id }) {
        id
      }
    }
  `,
  route: 'update_organizations_by_pk',
  variables: { id, enterprise_id },
})
