import extend from 'lodash/extend'
import flattenDeep from 'lodash/flattenDeep'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import moment from 'moment'
import orderBy from 'lodash/orderBy'
import sum from 'lodash/sum'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'

import CONFIG from '../config'
import { AiMode } from '../interfaces/AiMode'
import { Case_cases, Case_cases_medical_images } from '../hasura/graphQlQueries/types/Case'
import { Cases_cases } from '../hasura/graphQlQueries/types/Cases'
import { Conditions_conditions } from '../hasura/graphQlQueries/types/Conditions'
import { Consultations_consultations, Consultations_consultations_case } from '../hasura/graphQlQueries/types/Consultations'
import { commaSeparatedString, isExotic, keyForAwsS3Url, requestedImagesCount, searchQueryParams } from './helpers'
import { convertNumberToWords } from './numberToWords'
import { isUltrasoundCase } from './modalityHelpers'

import {
  ConsultationsForAiComparison_consultations,
  ConsultationsForAiComparison_consultations_case,
  ConsultationsForAiComparison_consultations_case_medical_images,
} from '../hasura/graphQlQueries/types/ConsultationsForAiComparison'

// @ts-ignore
import { get_predictions_for_case } from './ml_rules_engine.js'

// @ts-ignore
import aiConfig from '../../aiConfig.json'

type Case = Case_cases | Cases_cases | Consultations_consultations_case

export enum TaggingOption {
  Tag = 'tag',
  Review = 'review',
  Experiment = 'experiment',
}

export const isExperiment = () => searchQueryParams('t') === TaggingOption.Experiment

export const DEFAULT_MIN_AI_PROBABILITY = 0.5

export interface AiPredictionDenormalized {
  ml_name: string
  probability: number
}

export interface CasePrediction {
  prediction: AiPredictionDenormalized
  likelihood: string
}

export interface AiConfig {
  p_th: number
  views: string[]
  perspectives: string[]
}

export interface AiConfigSpecies {
  cat: { [key: string]: AiConfig }
  dog: { [key: string]: AiConfig }
}

export const specialistPredicted = (consultation: ConsultationsForAiComparison_consultations, conditionId: number) =>
  consultation.predictions.some((p) => p.condition?.id === conditionId)

export const getPossibleConditions = (
  c: Case | ConsultationsForAiComparison_consultations_case,
  conditions: Conditions_conditions[],
  aiMode: AiMode = AiMode.Default,
  onlyLikely = false
) => {
  const isErMode = aiMode === AiMode.ER
  const predictedConditions = orderBy(
    get_predictions_for_case(aiConfig, c, isErMode),
    [
      (p: CasePrediction) => ({ likely: 1, possible: 2, potential: 3 }[p.likelihood]),
      (p: CasePrediction) => conditions.find((c) => c.ml_name === p.prediction.ml_name)?.category?.order_index || 999,
      (p: CasePrediction) => conditions.find((c) => c.ml_name === p.prediction.ml_name)?.display_name,
    ],
    ['asc', 'asc', 'asc']
  )
    .filter((p: CasePrediction) => {
      if (!onlyLikely) return true

      const isLikely = p.likelihood === 'likely'
      const condition = conditions.find((c) => c.ml_name === p.prediction.ml_name)
      return isLikely || (condition?.severity && condition.severity > 2 && CONFIG.ER_MODE_CONDITIONS.includes(condition.display_name))
    })
    .map((p: CasePrediction) => conditions.find((c) => c.ml_name === p.prediction.ml_name))
  return compact(uniqBy(predictedConditions, 'id'))
}

export const negativePredictionForCondition = (conditionId: number, c?: Case) =>
  c?.predictions_normalizeds.find(
    (p2) => p2.type === 'gp-case' && p2.condition?.id === conditionId && p2.display_name?.includes('NOT')
  )

export enum Risk {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High',
}

export const conditionsForSpecies = (species: string, conditions: Conditions_conditions[]) => {
  if (isExotic(species)) return []

  try {
    return compact(
      uniq(
        Object.keys(aiConfig['combined-yolo'])
          .concat(...Object.keys(aiConfig[species]))
          .concat(...Object.keys(aiConfig[`${species}-yolo`] || {}))
          .map((ml_name) => conditions.find((c) => c.ml_name === ml_name)?.display_name)
      )
    ).sort()
  } catch (e) {
    console.error(e)
    return []
  }
}

export const riskForSeverity = (severity: number) => [Risk.Low, Risk.Medium, Risk.High][severity - 1]

export const getMaxSeverity = (c: Case, conditions: Conditions_conditions[], aiMode: AiMode) => {
  const aiConditions = getPossibleConditions(c, conditions, aiMode, aiMode === AiMode.ER).filter(
    (c2) => !negativePredictionForCondition(c2.id, c)
  )
  return Math.max(
    1,
    ...compact(
      getGpPredictions(c)
        .map((c) => c.condition?.severity)
        .concat(aiConditions.map((a) => a.severity))
    )
  )
}

export const getRisk = (c: Case, conditions: Conditions_conditions[], aiMode: AiMode) =>
  riskForSeverity(getMaxSeverity(c, conditions, aiMode))

export const getGpPredictions = (c: Case) =>
  c.predictions_normalizeds.filter((p) => p.type === 'gp-case' && !p.display_name?.includes('NOT'))

export const gpFilteredPredictions = (c: Case, conditions: Conditions_conditions[], aiMode: AiMode) =>
  getPossibleConditions(c, conditions, aiMode)
    .filter((p) => !negativePredictionForCondition(p.id, c))
    .map((p) => p.display_name)
    // @ts-ignore
    .concat(...getGpPredictions(c).map((p) => p.display_name || p.condition?.display_name))

enum ConfidenceRange {
  Low = 'Some confidence',
  Medium = 'Medium confidence',
  High = 'High confidence',
}

export const getConfidenceRange = (minProbability: number, probabilities: number[]) => {
  const averageProbability = sum(probabilities) / probabilities.length
  const highProbabilityThreshold = 1 - (1 - minProbability) * (1 / 5)
  const mediumHighProbabilityThreshold = 1 - (1 - minProbability) * (2 / 5)
  const mediumProbabilityThreshold = 1 - (1 - minProbability) * (3 / 5)
  const lowMediumProbabilityThreshold = 1 - (1 - minProbability) * (4 / 5)

  if (averageProbability >= highProbabilityThreshold) {
    return { probability: 95, confidenceRange: ConfidenceRange.High }
  } else if (averageProbability >= mediumHighProbabilityThreshold) {
    return { probability: 75, confidenceRange: ConfidenceRange.High }
  } else if (averageProbability >= mediumProbabilityThreshold) {
    return { probability: 55, confidenceRange: ConfidenceRange.Medium }
  } else if (averageProbability >= lowMediumProbabilityThreshold) {
    return { probability: 35, confidenceRange: ConfidenceRange.Medium }
  } else {
    return { probability: 15, confidenceRange: ConfidenceRange.Low }
  }
}

export const sentencesIncludingString = (paragraph: string, string: string) =>
  paragraph.split('.').filter((s) => s.toLowerCase().includes(string.toLowerCase()))

export const receivingVetNotesIntro = (consultation: Consultations_consultations | ConsultationsForAiComparison_consultations) => {
  if (isUltrasoundCase(consultation.case)) return

  const images = consultation.case.medical_images
  const imagesCount = requestedImagesCount(consultation)
  const views = images.map((m) => m.view?.display_name)
  const inView = ['abdomen', 'thorax', 'whole body'].filter((v) => views.some((v2) => v2?.toLowerCase().includes(v)))
  if (!inView.length || !imagesCount) return

  return `${capitalize(convertNumberToWords(imagesCount))} ${commaSeparatedString(inView)} image${
    imagesCount === 1 ? '' : 's'
  } dated ${moment(consultation.case.created_at).format('MMMM Do, YYYY')}.\n\n`
}

export const heatmapKeyFor = (
  m: Case_cases_medical_images | ConsultationsForAiComparison_consultations_case_medical_images,
  a: AiPredictionDenormalized
) => keyForAwsS3Url(m.aws_s3_url?.replace('COPY_', '')?.replace('.jpg', `_${a.ml_name.replace(/ /g, '_')}.png`))

export const getHeatmapImages = (c: Case_cases | ConsultationsForAiComparison_consultations_case) =>
  compact(
    flattenDeep(
      c.medical_images.map((m) =>
        flattenDeep(
          (m.ai_predictions_denormalized || [])
            .map((a: any) => extend({}, a, { aws_s3_url: m.aws_s3_url }))
            .concat(
              ...m.medical_image_permutations.map((p) =>
                (p.ai_predictions_denormalized || []).map((a: any) => extend({}, a, { aws_s3_url: p.aws_s3_url, label: p.label }))
              )
            )
        )
          .filter((a: any) => a.probability > 0.1)
          .map((a: any) => ({
            heatmap: heatmapKeyFor(m, a),
            cropped: a.label ? keyForAwsS3Url(a.aws_s3_url) : undefined,
            label: a.label,
            probability: a.probability,
          }))
      )
    )
  )
