import first from 'lodash/first'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { createSlice } from '@reduxjs/toolkit'

import PriceCalculator from '../../utils/PriceCalculator'
import callFlask from '../callFlask'
import callHasura from '../callHasura'
import CONFIG from '../../config'
import { CompletedConsultation } from './consultations'
import { NotificationId, setNotificationAction } from './notifications'
import { OrganizationEvents_organization_events } from '../graphQlQueries/types/OrganizationEvents'
import { Organizations_organizations } from '../graphQlQueries/types/Organizations'
import { PriceGroups_price_groups } from '../graphQlQueries/types/PriceGroups'
import { QueryName } from '../queryNames'
import { SalesData_organizations } from '../graphQlQueries/types/SalesData'
import { StripeIdForOrganization_users } from '../graphQlQueries/types/StripeIdForOrganization'
import { StripeInvoiceItem } from '../../interfaces/StripeInvoiceItem'
import { defaultSetLoading, defaultNetworkingFailure, defaultNetworkingSuccess } from './common'
import { fetchConsultationsQuery } from '../graphQlQueries/Consultation'
import { organization_events_insert_input } from '../../../types/globalTypes'

import {
  fetchOrganizationEventsQuery,
  fetchOrganizationsQuery,
  fetchPricesGroupsQuery,
  insertOrganizationEventQuery,
  insertOrganizationEventsQuery,
  salesDataQuery,
  stripeIdForOrganizationQuery,
  updateOrganizationQuery,
} from '../graphQlQueries/Organization'

export interface OrganizationsState {
  accessToken?: string
  hasErrors: boolean
  loading: boolean
  organizations: Organizations_organizations[]
  stripeCustomerId?: string
  draftInvoiceUrl?: string
  organizationsSalesData: SalesData_organizations[]
  organizationEvents: OrganizationEvents_organization_events[]
  priceGroups?: PriceGroups_price_groups[]
  isQuerying: any
}

const initialState: OrganizationsState = {
  loading: false,
  hasErrors: false,
  organizations: [],
  isQuerying: {},
  organizationsSalesData: [],
  organizationEvents: [],
}

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setLoading: defaultSetLoading,
    networkingFailure: defaultNetworkingFailure,
    networkingSuccess: defaultNetworkingSuccess,

    fetchOrganizationsSuccess: (state, { payload }) => {
      state.organizations = payload
      state.loading = false
      state.hasErrors = false
    },

    fetchOrganizationEventsSuccess: (state, { payload }) => {
      state.organizationEvents = payload
    },

    stripeIdForOrganizationSuccess: (state, { payload }: { payload: string }) => {
      state.stripeCustomerId = payload
      state.loading = false
      state.hasErrors = false
    },

    stripeIdForOrganization: (state) => {
      state.stripeCustomerId = undefined
    },

    createDraftInvoiceSuccess: (state, { payload }: { payload: string }) => {
      state.draftInvoiceUrl = payload
    },

    unsetDraftInvoiceUrl: (state) => {
      state.draftInvoiceUrl = undefined
    },

    fetchSalesDataSuccess: (state, { payload }: { payload: SalesData_organizations[] }) => {
      state.organizationsSalesData = payload
    },

    fetchPricesGroupsSuccess: (state, { payload }: { payload: PriceGroups_price_groups[] }) => {
      state.priceGroups = payload
    },
  },
})

export const {
  createDraftInvoiceSuccess,
  fetchOrganizationsSuccess,
  networkingFailure,
  networkingSuccess,
  setLoading,
  stripeIdForOrganization,
  fetchSalesDataSuccess,
  fetchPricesGroupsSuccess,
  fetchOrganizationEventsSuccess,
  stripeIdForOrganizationSuccess,
  unsetDraftInvoiceUrl,
} = organizationsSlice.actions

export const organizationsSelector = (state: any) => state.organizations

export default organizationsSlice.reducer

export function fetchOrganizationsAction(accessToken: string, enterprise_id?: number) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const organizations: Organizations_organizations[] = await callHasura(accessToken, fetchOrganizationsQuery(enterprise_id))
      const filtered = organizations.filter((o) => o.id !== CONFIG.TEST_ORGANIZATION_ID)
      dispatch(fetchOrganizationsSuccess(filtered))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchSalesDataAction(accessToken: string) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      const organizationsSalesData = await callHasura(accessToken, salesDataQuery())
      dispatch(fetchSalesDataSuccess(organizationsSalesData))
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function updateOrganizationAction(
  accessToken: string,
  id: number,
  display_pricing: boolean,
  display_name: string,
  pims_email?: string,
  primary_phone_number?: string,
  number_of_vets?: string,
  default_weight_unit?: string
) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(
        accessToken,
        updateOrganizationQuery(
          id,
          display_pricing,
          display_name,
          pims_email,
          primary_phone_number,
          number_of_vets,
          default_weight_unit
        )
      )

      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function stripeIdForOrganizationAction(accessToken: string, id: number) {
  return async (dispatch: any) => {
    dispatch(stripeIdForOrganization())

    try {
      const users: StripeIdForOrganization_users[] = await callHasura(accessToken, stripeIdForOrganizationQuery(id))
      const customerId = first(users)?.related_accounts?.stripe_profile?.id

      if (customerId) {
        dispatch(stripeIdForOrganizationSuccess(customerId))
      } else {
        dispatch(networkingFailure())
      }
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function createDraftInvoiceAction(customer_id: string, invoice_items: StripeInvoiceItem[]) {
  return async (dispatch: any) => {
    dispatch(unsetDraftInvoiceUrl())

    try {
      const result = await callFlask(`/invoice`, 'POST', { customer_id, invoice_items })

      if (result.url) {
        dispatch(createDraftInvoiceSuccess(result.url))
      } else {
        dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      }
    } catch (error) {
      dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
    }
  }
}

export function createDraftInvoiceBatchAction(accessToken: string, enterpriseId: number, gt: string, lt: string) {
  return async (dispatch: any) => {
    const queryName = QueryName.DraftInvoiceBatch

    try {
      dispatch(setLoading(queryName))

      const consultations: CompletedConsultation[] = await callHasura(accessToken, fetchConsultationsQuery([enterpriseId], gt, lt))
      const filteredForEnterprise = consultations.filter((c) => c.case.dicom_server?.organization?.enterprise.id === enterpriseId)
      const grouped = mapValues(
        groupBy(filteredForEnterprise, (c) => c.case.dicom_server?.organization?.id),
        (values) => PriceCalculator.invoiceItemsFromConsultations(values)
      )
      callFlask(`/invoice/batch`, 'POST', grouped)

      dispatch(setNotificationAction(NotificationId.CheckYourEmail))
      dispatch(networkingSuccess(queryName))
    } catch (error) {
      dispatch(setNotificationAction(NotificationId.SomethingBadHappened))
      dispatch(networkingFailure(queryName))
    }
  }
}

export function unsetDraftInvoiceUrlAction() {
  return async (dispatch: any) => {
    dispatch(unsetDraftInvoiceUrl())
  }
}

export function insertOrganizationEventAction(accessToken: string, object: organization_events_insert_input) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertOrganizationEventQuery(object))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertOrganizationEventForUserAction(
  accessToken: string,
  organization_event_type_id: number,
  organization_id?: number,
  additional_data?: any
) {
  return async (dispatch: any) => {
    if (!organization_id) return

    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertOrganizationEventQuery({ organization_id, organization_event_type_id, additional_data }))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function insertOrganizationEventsAction(accessToken: string, events: organization_events_insert_input[]) {
  return async (dispatch: any) => {
    dispatch(setLoading())

    try {
      await callHasura(accessToken, insertOrganizationEventsQuery(events))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}

export function fetchOrganizationEventsAction(accessToken: string) {
  return async (dispatch: any) => {
    const query = fetchOrganizationEventsQuery()
    dispatch(setLoading(query.name))

    try {
      const organizationEvents: OrganizationEvents_organization_events[] = await callHasura(accessToken, query)
      dispatch(fetchOrganizationEventsSuccess(organizationEvents))
      dispatch(networkingSuccess(query.name))
    } catch (error) {
      dispatch(networkingFailure(query.name))
    }
  }
}

export function fetchPricesGroupsAction(accessToken: string, enterprise_id: number) {
  return async (dispatch: any) => {
    try {
      const pricesGroups: PriceGroups_price_groups[] = await callHasura(accessToken, fetchPricesGroupsQuery(enterprise_id))
      dispatch(fetchPricesGroupsSuccess(pricesGroups))
      dispatch(networkingSuccess())
    } catch (error) {
      dispatch(networkingFailure())
    }
  }
}
